* {
  font-family: "Varela Round", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.cdnfonts.com/css/rusty-forest-personal-use');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');


@import url(//db.onlinewebfonts.com/c/18f8349c2d959b29f148cbbe8c4d2513?family=Comic+Kings);
@import url('https://fonts.cdnfonts.com/css/edo');

@font-face {
  font-family: "Comic Kings";
  src: url("//db.onlinewebfonts.com/t/18f8349c2d959b29f148cbbe8c4d2513.eot");
  src: url("//db.onlinewebfonts.com/t/18f8349c2d959b29f148cbbe8c4d2513.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/18f8349c2d959b29f148cbbe8c4d2513.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/18f8349c2d959b29f148cbbe8c4d2513.woff") format("woff"), url("//db.onlinewebfonts.com/t/18f8349c2d959b29f148cbbe8c4d2513.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/18f8349c2d959b29f148cbbe8c4d2513.svg#Comic Kings") format("svg");
}


.boxWrap2Footer {
  background-color: #ffdeb249;
  border-top: 8px dashed burlywood;
}


.contractAdd{
  color: #8d807b;
}

.solF{
  background-color: #000000;
  color: white;
  padding: 3px;
}

.closeNote {
  position: absolute;
  right: 0;
  top: 5%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: fit-content;
  cursor: pointer;
  border: none;
  transition: transform .2s ease-in-out;
}

.closeNote:hover {
  color: rgb(218, 218, 218);
  background-color: #2c993000;
}




.gem2 {
  width: 17%;
  height: 17%;
  margin-top: -80px;
  z-index: 1000000;
  position: relative;
  margin-right: 40px;
}

.gemDiv {
  display: flex;
  justify-content: space-between;
}


.tokenMain {
  background: rgb(120, 232, 173);
  background: radial-gradient(circle, rgba(120, 232, 172, 0.315) 0%, rgba(23, 182, 161, 0.171) 50%, rgba(80, 251, 251, 0.24) 100%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 3px solid rgba(9, 196, 140, 0.26);
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 50px;
  width: 95%;
  border-radius: 50px;
}

.presaleT {
  font-family: 'Rusty Forest PERSONAL USE', sans-serif;
  color: #599993;
}

.tokenCon {
  text-align: left;
  width: 45%;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.presale-container {
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  margin-top: 2%;

}

/* Input styles */
.input-container {
  margin-bottom: 20px;
  display: flex;
  border: 2px solid #ddd;
  border-radius: 1px;
  background-color: white;

}

.inSol {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-flow: row nowrap;
  margin-right: 30px;
  font-size: 18px;
}

.input-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
  text-align: center;
  background: none;
  border: none;
}

.input-field:focus {
  border-color: #1a8116;
}

/* Button styles */
.mint-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #1a8116;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
  width: 100%;
}

.mint-btn:hover {
  background-color: #82a12a;
}

.copy:hover {
  cursor: pointer;
  opacity: 0.5;
}

.hidden {
  display: none;
}



@media screen and (max-width: 499px) {

  .x{
    width: 38px;
    height: 38px;
    cursor: pointer;
  }

  .navT{
    font-family: 'Edo', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }

  .logoDiv{
    display: flex;
  }

  .tokenMain2 {
    width: 90%;
    text-align: left;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .banner {
    width: 90%;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.856);
    z-index: 1000000000000000;
  }

  .popUpDiv {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 90%;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 1000000000000000;
    display: flex;
    flex-flow: row nowrap;
    animation: popupAnimation 0.5s ease forwards;
    color: white;
    flex-flow: column nowrap;
  }

  .gem {
    width: 35%;
    height: 35%;
    margin-top: -25%;
    z-index: 1000000;
    position: relative;
    margin-left: 40px;
  }

  
  .note4{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 14px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 31%;  
    margin-left: 16%;    
    z-index: 10;
  }
 
  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
     position: absolute;
    z-index: 10;
    font-family: 'Edo', sans-serif;
    margin-top: 38%;
    margin-left: 15%;
    border: 1px solid black;
    border-radius: 20px;
    padding-bottom: 10px;
    padding: 5px;

  }
  
  .timer div {
    text-align: center;
    font-family: 'Edo', sans-serif;
    width: 48px;
  }
  
  .timer div span {
    display: block;
    font-size: 0.6rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  
  }
  
  .noteMain{
    position: relative;
    rotate: -3deg;
  }
  
  .noteImg{
    position: absolute;
    width: 100%;
    margin-top: -8%;
    margin-left: -50%;
    width: 100%;
    height: 500px;
  }
  
  .note1{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 14px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 12%;  
    margin-left: 16%;                                 
  }
  
  .note2{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 32px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 15%;  
    margin-left: 34%;   
    rotate: -6deg;                              
  }
  
  .note3{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 19px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 60%;  
    margin-left: 16%;    
  }
  
  .note {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 16px;
    z-index: 10;
    position: absolute;
    margin-top: 115%;
    margin-left: 17%;
  }

  .tokenMain {
    background: rgb(120, 232, 173);
    background: radial-gradient(circle, rgba(120, 232, 172, 0.315) 0%, rgba(23, 182, 161, 0.171) 50%, rgba(80, 251, 251, 0.24) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(9, 196, 140, 0.26);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    width: 95%;
    border-radius: 50px;
  }

  .presaleT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    color: #599993;
  }

  .solP {
    font-size: 11px;
    color: #666666;
    font-weight: bold;
    text-align: center;
    margin-bottom: 8px;
  }

  .tokenMain2 {
    width: 75%;
    text-align: center;
    z-index: 10;
    margin-top: 25%;
    margin-bottom: 3%;
    position: absolute;
  }

  .banner {
    width: 75%;
  }

  .tokenCon {
    text-align: left;
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #1a8116;
  }

  .presale-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;

  }

  /* Input styles */
  .input-container {
    margin-bottom: 20px;
    display: flex;
    border: 2px solid #000000;
    border-radius: 1px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: absolute;
    margin-top: 83%;
    margin-left: 16%;
    z-index: 5;
    border-radius: 30px;    
    width: 180px;
  }

  .inSol {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: row nowrap;
    margin-right: 50px;
    font-size: 16px;
    font-family: 'Edo', sans-serif;

  }

  .input-field {
    width: 180px;
    padding: 10px;
    font-size: 13px;
    outline: none;
    transition: border-color 0.3s ease;
    text-align: center;
    border: none;
    background: none;
  }

  .input-field:focus {
    border-color: #1a8116;
  }

  /* Button styles */
  .mint-btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #1a8116;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    width: 180px;
    border-radius: 1px;
    z-index: 5;
    position: absolute;
    margin-top: 99%;
    margin-left: -34%;
    border-radius: 50px;
    font-family: 'Edo', sans-serif;

  }

  .videoBg {
    position: absolute;
    top: -300px;
    -webkit-transform: translateX(-50%) translateY(-10%);
    transform: translateX(-50%) translateY(-10%);

    width: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
  }

  .stones {
    width: 100%;
  }



  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logoDiv img {
    width: 60px;
    border-radius: 100px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 12px;
    cursor: pointer;
    color: #6e6e4c;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: bold;
  }

  .mintBtn {
    font-size: 16px;
    background-color: #e3ff96;
    border: 1px #788f38 solid;
    border-radius: 12px;
    padding: 6px;
    color: black;
    margin-left: 15px;
  }

  #fontSize:hover {
    color: #05a500;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 7px;
    margin-right: 7px;
  }

  .allWrap {
    background: rgb(33, 66, 0);
    background: linear-gradient(299deg, rgba(33, 66, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(19, 38, 0, 1) 100%);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: url('./assets/bg.jpg') fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont {
    height: max-content;
    background-size: cover;
    text-align: right;
    width: 100%;
    overflow: hidden;
   
    height: 140vh;
  }


  .introduction {
    text-align: center;
    padding-top: 2%;
    padding-bottom: 5%;
    justify-content: center;
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    z-index: 1000;
    
  }

  .introduction-2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .introPic {
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-top: 2%;
    border: 1px solid rgb(208, 255, 0);
    box-shadow: rgba(164, 192, 86, 0.35) 0px 10px 20px;
    rotate: 6deg;
    display: none;
  }

  .introPicMob {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-top: 2%;
    border: 1px solid rgb(208, 255, 0);
    box-shadow: rgba(164, 192, 86, 0.35) 0px 10px 20px;
    rotate: 6deg;
    margin-bottom: 60px;
  }

  .social {
    display: flex;
    margin-top: 2%;
    margin-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .social img {
    width: 35px;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .social img:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .soocialDiv {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .buy {
    font-size: 15px;
    background-color: #97e92c;
    border: 1px #2b8a7a solid;
    border-radius: 12px;
    padding: 10px;
    margin-right: 10px;
    color: #093331;
    width: fit-content;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buy:hover {
    background-color: #d1ff05;
  }

  .title {
    font-size: 20px;
    -webkit-text-stroke: 2px rgba(0, 0, 0, 0);
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .mememon {
    font-family: 'Comic Kings', sans-serif;
    font-size: 65px;
    color: #ffffff;
    text-shadow: 2px 8px 6px #21352418, 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 2px #9bbe7d;
    line-height: 80px;
    text-align: center;
    margin: auto;
  }

  .fontSize2 {
    font-size: 20px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
    text-align: center;
    margin: auto;
  }

  .subTitle {
    font-size: 16px;
    width: 90%;
    color: #83aa89;
  }

  .linkMain {
    font-weight: 500;
    width: fit-content;
    justify-content: space-between;
     width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .inputDiv {
    color: rgb(255, 148, 86);
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    outline: none;
    background: none;
  }

  input::placeholder {
    color: #659c32;
    /* Set the desired placeholder color */
  }

  .copy {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }

  .aboutMain {
    margin-top: 7%;
    text-align: center;
  }

  .flexStone {
    display: flex;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .stoneS {
    width: 25%;
    height: 25%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }


  .stoneS2 {
    width: 10%;
    margin-right: 5%;
   }

  .aboutMain--2 {
    margin-top: 7%;
    text-align: center;
  }

  .imgStone {
    width: 100%;
    z-index: 1000;
    position: relative;
    margin-top: -40px;
  }

  .conT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    font-size: 60px;
    text-decoration: underline #fa7e1d;
    color: #f3ef7d;
  }

  .conT2 {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;
  }

  .conT2-2 {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;

  }

  .conT2-HL {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout img {
    width: 40%;
    border-radius: 100px;
    margin-top: -80px;
  }

  .boxAbout {
    background-color: #baf7d4;
    width: 400px;
    padding: 30px;
    border-radius: 80px;
    margin: 40px;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .boxAbout div {
    font-size: 25px;
    margin-top: 5%;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .boxAbout p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 16px;
  }

  .boxAbout2 img {
    width: 20%;
    margin-bottom: 25px;
  }

  .boxAbout2 {
    margin-bottom: 20px;
    margin: 20px;
    text-align: left;
    background-color: #befff41f;
    width: 90%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px dashed #49d1ba;
  }

  .hlF {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout2 p {
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .box2Main {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .boxMain {
    margin-top: 4%;
  }

  .tierMain {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    flex-flow: column nowrap;
  }

  .tierMain p {
    font-size: 18px;
  }

  .tierSection1 {
    width: 90%;
    padding: 50px;
    border-radius: 30px;
    transform: rotate(-10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tierSection2 {
    width: 90%;
    padding: 50px;
    border-radius: 30px;
    transform: rotate(10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }

  .tierSection2-2 {
    margin-top: 35px;
  }

  .tierH {
    font-family: "Kalam", cursive;
    font-size: 30px;

    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }



  .iconsF {
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF img {
    width: 50px;
    height: 50px;
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 150px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .shell1 {
    width: 18%;
    height: 18%;
    position: sticky;
    margin-left: 3%;
    margin-top: -2%;
  }

  .shell2 {
    margin-top: -9%;
    width: 18%;
    height: 18%;
    margin-right: 3%;
  }

  .shells2 {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 7%;
    padding-bottom: 5%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 20px;
    margin-top: 20px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .tokenMain2 {
    width: 90%;
    text-align: left;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  .x{
    width: 38px;
    height: 38px;
    cursor: pointer;
  }

  .navT{
    font-family: 'Edo', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }

  .logoDiv{
    display: flex;
  }

  .banner {
    width: 90%;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.856);
    z-index: 1000000000000000;
  }

  .popUpDiv {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 90%;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 1000000000000000;
    display: flex;
    flex-flow: row nowrap;
    animation: popupAnimation 0.5s ease forwards;
    color: white;
    flex-flow: column nowrap;
  }

  .gem {
    width: 35%;
    height: 35%;
    margin-top: -25%;
    z-index: 1000000;
    position: relative;
    margin-left: 40px;
  }

  
  .note4{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 16px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 21%;  
    margin-left: 16%;    
    z-index: 10;
  }
 
  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
     position: absolute;
    z-index: 10;
    font-family: 'Edo', sans-serif;
    margin-top: 27%;
    margin-left: 15%;
    border: 1px solid black;
    border-radius: 20px;
    padding-bottom: 10px;
    padding: 5px;

  }
  
  .timer div {
    text-align: center;
    font-family: 'Edo', sans-serif;
    width: 60px;
  }
  
  .timer div span {
    display: block;
    font-size: 0.8rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  
  }
  
  .noteMain{
    position: relative;
    rotate: -3deg;
  }
  
  .noteImg{
    position: absolute;
    width: 100%;
    margin-top: -8%;
    margin-left: -50%;
  }
  
  .note1{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 19px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 5%;  
    margin-left: 13%;                                 
  }
  
  .note2{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 32px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 10%;  
    margin-left: 34%;   
    rotate: -6deg;                              
  }
  
  .note3{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 19px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 45%;  
    margin-left: 16%;    
  }
  
  .note {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 18px;
    z-index: 10;
    position: absolute;
    margin-top: 90%;
    margin-left: 23%;
  }

  .tokenMain {
    background: rgb(120, 232, 173);
    background: radial-gradient(circle, rgba(120, 232, 172, 0.315) 0%, rgba(23, 182, 161, 0.171) 50%, rgba(80, 251, 251, 0.24) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(9, 196, 140, 0.26);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    width: 95%;
    border-radius: 50px;
  }

  .presaleT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    color: #599993;
  }

  .solP {
    font-size: 14px;
    color: #666666;
    font-weight: bold;
    text-align: center;
    margin-bottom: 8px;
  }

  .tokenMain2 {
    width: 75%;
    text-align: center;
    z-index: 10;
    margin-top: 25%;
    margin-bottom: 3%;
    position: absolute;
  }

  .banner {
    width: 75%;
  }

  .tokenCon {
    text-align: left;
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #1a8116;
  }

  .presale-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;

  }

  /* Input styles */
  .input-container {
    margin-bottom: 20px;
    display: flex;
    border: 2px solid #000000;
    border-radius: 1px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: absolute;
    margin-top: 65%;
    margin-left: 16%;
    z-index: 5;
    border-radius: 30px;    
  }

  .inSol {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: row nowrap;
    margin-right: 30px;
    font-size: 16px;
    font-family: 'Edo', sans-serif;

  }

  .input-field {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
    text-align: center;
    border: none;
    background: none;
  }

  .input-field:focus {
    border-color: #1a8116;
  }

  /* Button styles */
  .mint-btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #1a8116;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    width: 260px;
    border-radius: 1px;
    z-index: 5;
    position: absolute;
    margin-top: 78%;
    margin-left: -34%;
    border-radius: 50px;
    font-family: 'Edo', sans-serif;

  }


  .videoBg {
    position: absolute;
    top: -300px;
    -webkit-transform: translateX(-50%) translateY(-10%);
    transform: translateX(-50%) translateY(-10%);

    width: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
  }

  .stones {
    width: 100%;
  }



  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logoDiv img {
    width: 80px;
    border-radius: 100px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 13px;
    cursor: pointer;
    color: #6e6e4c;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: bold;
  }

  .mintBtn {
    font-size: 14px;
    background-color: #e3ff96;
    border: 1px #788f38 solid;
    border-radius: 12px;
    padding: 12px;
    color: black;
    margin-left: 15px;
  }

  #fontSize:hover {
    color: #05a500;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 12px;
    margin-right: 12px;
  }

  .allWrap {
    background: rgb(33, 66, 0);
    background: linear-gradient(299deg, rgba(33, 66, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(19, 38, 0, 1) 100%);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: url('./assets/bg.jpg') fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont {
    height: max-content;
    background-size: cover;
    text-align: right;
    width: 100%;
    overflow: hidden;
   
    height: 170vh;
  }


  .introduction {
    text-align: center;
    padding-top: 2%;
    padding-bottom: 5%;
    justify-content: center;
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    z-index: 1000;
    
  }

  .introduction-2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .introPic {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-top: 2%;
    margin-bottom: 5%;
    border: 1px solid rgb(208, 255, 0);
    box-shadow: rgba(164, 192, 86, 0.35) 0px 10px 20px;
    rotate: 6deg;
    display: none;
  }

  .introPicMob {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-top: 2%;
    margin-bottom: 5%;
    border: 1px solid rgb(208, 255, 0);
    box-shadow: rgba(164, 192, 86, 0.35) 0px 10px 20px;
    rotate: 6deg;
    margin-bottom: 60px;

  }

  .social {
    display: flex;
    margin-top: 2%;
    margin-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .social img {
    width: 35px;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .social img:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .soocialDiv {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .buy {
    font-size: 15px;
    background-color: #97e92c;
    border: 1px #2b8a7a solid;
    border-radius: 12px;
    padding: 10px;
    margin-right: 10px;
    color: #093331;
    width: fit-content;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buy:hover {
    background-color: #d1ff05;
  }

  .title {
    font-size: 20px;
    -webkit-text-stroke: 2px rgba(0, 0, 0, 0);
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .mememon {
    font-family: 'Comic Kings', sans-serif;
    font-size: 65px;
    color: #ffffff;
    text-shadow: 2px 8px 6px #21352418, 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 2px #9bbe7d;
    line-height: 80px;
    text-align: center;
    margin: auto;
  }

  .fontSize2 {
    font-size: 20px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
    text-align: center;
    margin: auto;
  }

  .subTitle {
    font-size: 16px;
    width: 90%;
    color: #83aa89;
  }

  .linkMain {
    font-weight: 500;
    width: fit-content;
    justify-content: space-between;
     width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .inputDiv {
    color: rgb(255, 148, 86);
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    outline: none;
    background: none;
  }

  input::placeholder {
    color: #659c32;
    /* Set the desired placeholder color */
  }

  .copy {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }

  .aboutMain {
    margin-top: 7%;
    text-align: center;
  }

  .flexStone {
    display: flex;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .stoneS {
    width: 25%;
    height: 25%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }


  .stoneS2 {
    width: 10%;
    margin-right: 5%;
   }

  .aboutMain--2 {
    margin-top: 7%;
    text-align: center;
  }

  .imgStone {
    width: 100%;
    z-index: 1000;
    position: relative;
    margin-top: -40px;
  }

  .conT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    font-size: 60px;
    text-decoration: underline #fa7e1d;
    color: #f3ef7d;
  }

  .conT2 {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;
  }

  .conT2-2 {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;

  }

  .conT2-HL {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout img {
    width: 40%;
    border-radius: 100px;
    margin-top: -80px;
  }

  .boxAbout {
    background-color: #baf7d4;
    width: 400px;
    padding: 30px;
    border-radius: 80px;
    margin: 40px;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .boxAbout div {
    font-size: 25px;
    margin-top: 5%;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .boxAbout p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 16px;
  }

  .boxAbout2 img {
    width: 20%;
    margin-bottom: 25px;
  }

  .boxAbout2 {
    margin-bottom: 20px;
    margin: 20px;
    text-align: left;
    background-color: #befff41f;
    width: 90%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px dashed #49d1ba;
  }

  .hlF {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout2 p {
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .box2Main {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .boxMain {
    margin-top: 4%;
  }

  .tierMain {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    flex-flow: column nowrap;
  }

  .tierMain p {
    font-size: 18px;
  }

  .tierSection1 {
    width: 90%;
    padding: 50px;
    border-radius: 30px;
    transform: rotate(-10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tierSection2 {
    width: 90%;
    padding: 50px;
    border-radius: 30px;
    transform: rotate(10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }

  .tierSection2-2 {
    margin-top: 35px;
  }

  .tierH {
    font-family: "Kalam", cursive;
    font-size: 30px;

    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }



  .iconsF {
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF img {
    width: 50px;
    height: 50px;
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 150px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .shell1 {
    width: 18%;
    height: 18%;
    position: sticky;
    margin-left: 3%;
    margin-top: -2%;
  }

  .shell2 {
    margin-top: -9%;
    width: 18%;
    height: 18%;
    margin-right: 3%;
  }

  .shells2 {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 7%;
    padding-bottom: 5%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 20px;
    margin-top: 20px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .x{
    width: 38px;
    height: 38px;
    cursor: pointer;
  }

  .navT{
    font-family: 'Edo', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }

  .logoDiv{
    display: flex;
  }

  
  .tokenMain2 {
    width: 90%;
    text-align: left;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .banner {
    width: 90%;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.856);
    z-index: 1000000000000000;
  }

  .popUpDiv {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 90%;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 1000000000000000;
    display: flex;
    flex-flow: row nowrap;
    animation: popupAnimation 0.5s ease forwards;
    color: white;
    flex-flow: column nowrap;
  }

  .gem {
    width: 25%;
    height: 25%;
    margin-top: -25%;
    z-index: 1000000;
    position: relative;
    margin-left: 40px;
  }

  .tokenMain {
    background: rgb(120, 232, 173);
    background: radial-gradient(circle, rgba(120, 232, 172, 0.315) 0%, rgba(23, 182, 161, 0.171) 50%, rgba(80, 251, 251, 0.24) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(9, 196, 140, 0.26);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    width: 95%;
    border-radius: 50px;
  }

  .presaleT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    color: #599993;
  }

  
  .note4{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 16px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 15%;  
    margin-left: 32%;    
    z-index: 10;
  }
 
  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
     position: absolute;
    z-index: 10;
    font-family: 'Edo', sans-serif;
    margin-top: 19%;
    margin-left: 32%;
    border: 1px solid black;
    border-radius: 20px;
    padding-bottom: 10px;
    padding: 5px;

  }
  
  .timer div {
    text-align: center;
    font-family: 'Edo', sans-serif;
    width: 60px;
  }
  
  .timer div span {
    display: block;
    font-size: 0.8rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  
  }
  
  .noteMain{
    position: relative;
    rotate: -3deg;
  }
  
  .noteImg{
    position: absolute;
    width: 68%;
    margin-top: -5%;
    margin-left: -30%;
    
  }
  
  .note1{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 19px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 4%;  
    margin-left: 30%;                                 
  }
  
  .note2{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 32px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 7%;  
    margin-left: 42%;   
    rotate: -6deg;                              
  }
  
  .note3{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 19px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 32%;  
    margin-left: 30%;    
  }
  
  .note {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 18px;
    z-index: 10;
    position: absolute;
    margin-top: 59%;
    margin-left: 35%;
  }

  .tokenMain {
    background: rgb(120, 232, 173);
    background: radial-gradient(circle, rgba(120, 232, 172, 0.315) 0%, rgba(23, 182, 161, 0.171) 50%, rgba(80, 251, 251, 0.24) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(9, 196, 140, 0.26);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    width: 95%;
    border-radius: 50px;
  }

  .presaleT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    color: #599993;
  }

  .solP {
    font-size: 14px;
    color: #666666;
    font-weight: bold;
    text-align: center;
    margin-bottom: 8px;
  }

  .tokenMain2 {
    width: 75%;
    text-align: center;
    z-index: 10;
    margin-top: 25%;
    margin-bottom: 3%;
    position: absolute;
  }

  .banner {
    width: 75%;
  }

  .tokenCon {
    text-align: left;
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #1a8116;
  }

  .presale-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;

  }

  /* Input styles */
  .input-container {
    margin-bottom: 20px;
    display: flex;
    border: 2px solid #000000;
    border-radius: 1px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: absolute;
    margin-top: 45%;
    margin-left: 31%;
    z-index: 5;
    border-radius: 30px;    
  }

  .inSol {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: row nowrap;
    margin-right: 30px;
    font-size: 15px;
    font-family: 'Edo', sans-serif;

  }

  .input-field {
    width: 200px;
    padding: 10px;
    font-size: 15px;
    outline: none;
    transition: border-color 0.3s ease;
    text-align: center;
    border: none;
    background: none;
  }

  .input-field:focus {
    border-color: #1a8116;
  }

  /* Button styles */
  .mint-btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #1a8116;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    width: 260px;
    border-radius: 1px;
    z-index: 5;
    position: absolute;
    margin-top: 53%;
    margin-left: -19%;
    border-radius: 50px;
    font-family: 'Edo', sans-serif;

  }


  .videoBg {
    position: absolute;
    top: -200px;
    -webkit-transform: translateX(-50%) translateY(-10%);
    transform: translateX(-50%) translateY(-10%);

    width: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
  }

  .stones {
    width: 100%;
  }



  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logoDiv img {
    width: 80px;
    border-radius: 100px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 15px;
    cursor: pointer;
    color: #6e6e4c;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: bold;
  }

  .mintBtn {
    font-size: 17px;
    background-color: #e3ff96;
    border: 1px #788f38 solid;
    border-radius: 12px;
    padding: 12px;
    color: black;
    margin-left: 15px;
  }

  #fontSize:hover {
    color: #05a500;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 25px;
    margin-right: 25px;
  }

  .allWrap {
    background: rgb(33, 66, 0);
    background: linear-gradient(299deg, rgba(33, 66, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(19, 38, 0, 1) 100%);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: url('./assets/bg.jpg') fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont {
    height: max-content;
    background-size: cover;
    text-align: right;
   
    height: 180vh;
  }


  .introduction {
    text-align: center;
    padding-top: 2%;
    padding-bottom: 5%;
    justify-content: center;
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    z-index: 1000;
    
  }

  .introduction-2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .introPic {
    width: 350px;
    height: 350px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    border: 1px solid rgb(208, 255, 0);
    box-shadow: rgba(164, 192, 86, 0.35) 0px 10px 20px;
    rotate: 6deg;
    display: none;
  }

  .introPicMob {
    width: 350px;
    height: 350px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    border: 1px solid rgb(208, 255, 0);
    box-shadow: rgba(164, 192, 86, 0.35) 0px 10px 20px;
    rotate: 6deg;
    margin-bottom: 60px;

  }

  .social {
    display: flex;
    margin-top: 2%;
    margin-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .social img {
    width: 35px;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .social img:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .soocialDiv {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .buy {
    font-size: 15px;
    background-color: #97e92c;
    border: 1px #2b8a7a solid;
    border-radius: 12px;
    padding: 10px;
    margin-right: 10px;
    color: #093331;
    width: fit-content;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buy:hover {
    background-color: #d1ff05;
  }

  .title {
    font-size: 20px;
    -webkit-text-stroke: 2px rgba(0, 0, 0, 0);
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .mememon {
    font-family: 'Comic Kings', sans-serif;
    font-size: 85px;
    color: #ffffff;
    text-shadow: 2px 8px 6px #21352418, 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 2px #9bbe7d;
    line-height: 100px;
    text-align: center;
    margin: auto;
  }

  .fontSize2 {
    font-size: 20px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
    text-align: center;
    margin: auto;
  }

  .subTitle {
    font-size: 16px;
    width: 90%;
    color: #83aa89;
  }

  .linkMain {
    font-weight: 500;
    width: fit-content;
    justify-content: space-between;
     width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .inputDiv {
    color: rgb(255, 148, 86);
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    outline: none;
    background: none;
  }

  input::placeholder {
    color: #659c32;
    /* Set the desired placeholder color */
  }

  .copy {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }

  .aboutMain {
    margin-top: 7%;
    text-align: center;
  }

  .flexStone {
    display: flex;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .stoneS {
    width: 25%;
    height: 25%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }


  .stoneS2 {
    width: 10%;
    margin-right: 5%;
   }

  .aboutMain--2 {
    margin-top: 7%;
    text-align: center;
  }

  .imgStone {
    width: 100%;
    z-index: 1000;
    position: relative;
    margin-top: -70px;
  }

  .conT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    font-size: 60px;
    text-decoration: underline #fa7e1d;
    color: #f3ef7d;
  }

  .conT2 {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;
  }

  .conT2-2 {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;

  }

  .conT2-HL {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout img {
    width: 40%;
    border-radius: 100px;
    margin-top: -80px;
  }

  .boxAbout {
    background-color: #baf7d4;
    width: 400px;
    padding: 30px;
    border-radius: 80px;
    margin: 15px;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  }

  .boxAbout div {
    font-size: 25px;
    margin-top: 5%;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .boxAbout p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 16px;
  }

  .boxAbout2 img {
    width: 20%;
    margin-bottom: 25px;
  }

  .boxAbout2 {
    margin-bottom: 20px;
    margin: 20px;
    text-align: left;
    background-color: #befff41f;
    width: 80%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px dashed #49d1ba;
  }

  .hlF {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout2 p {
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .box2Main {
    display: flex;
    justify-content: center;
  }

  .boxMain {
    margin-top: 4%;
  }

  .tierMain {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    flex-flow: column nowrap;
  }

  .tierMain p {
    font-size: 18px;
  }

  .tierSection1 {
    width: 60%;
    padding: 50px;
    border-radius: 30px;
    transform: rotate(-10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tierSection2 {
    width: 60%;
    padding: 50px;
    border-radius: 30px;
    transform: rotate(10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }

  .tierSection2-2 {
    margin-top: 35px;
  }

  .tierH {
    font-family: "Kalam", cursive;
    font-size: 40px;

    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }



  .iconsF {
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF img {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 180px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .shell1 {
    width: 18%;
    height: 18%;
    position: sticky;
    margin-left: 3%;
    margin-top: -2%;
  }

  .shell2 {
    margin-top: -9%;
    width: 18%;
    height: 18%;
    margin-right: 3%;
  }

  .shells2 {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 7%;
    padding-bottom: 5%;
  }

  .copyright {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 25px;
    margin-top: 20px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .x{
    width: 38px;
    height: 38px;
    cursor: pointer;
  }

  .navT{
    font-family: 'Edo', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }

  .logoDiv{
    display: flex;
  }

  .introPicMob{
    display: none;
  }
  
  .tokenMain2 {
    width: 75%;
    text-align: left;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .banner {
    width: 75%;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.856);
    z-index: 1000000000000000;
  }

  .popUpDiv {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 90%;
    height: 85vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 1000000000000000;
    display: flex;
    flex-flow: row nowrap;
    animation: popupAnimation 0.5s ease forwards;
    color: white;
    flex-flow: column nowrap;
  }

  .gem {
    width: 25%;
    height: 25%;
    margin-top: -25%;
    z-index: 1000000;
    position: relative;
    margin-left: 40px;
  }

  .note4{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 16px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 16%;  
    margin-left: 16%;    
    z-index: 10;
  }
 
  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
     position: absolute;
    z-index: 10;
    font-family: 'Edo', sans-serif;
    margin-top: 21.5%;
    margin-left: 15%;
    border: 1px solid black;
    border-radius: 20px;
    padding-bottom: 10px;
    padding: 5px;

  }
  
  .timer div {
    text-align: center;
    font-family: 'Edo', sans-serif;
    width: 60px;
  }
  
  .timer div span {
    display: block;
    font-size: 0.8rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  
  }
  
  .noteMain{
    position: relative;
    rotate: -3deg;
  }
  
  .noteImg{
    position: absolute;
    width: 90%;
    margin-top: -8%;
  }
  
  .note1{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 19px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 2.4%;  
    margin-left: 13%;                                 
  }
  
  .note2{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 32px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 6%;  
    margin-left: 27%;   
    rotate: -6deg;                              
  }
  
  .note3{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 19px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 37%;  
    margin-left: 16%;    
  }
  
  .note {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 18px;
    z-index: 10;
    position: absolute;
    margin-top: 76%;
    margin-left: 20%;
  }

  .tokenMain {
    background: rgb(120, 232, 173);
    background: radial-gradient(circle, rgba(120, 232, 172, 0.315) 0%, rgba(23, 182, 161, 0.171) 50%, rgba(80, 251, 251, 0.24) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(9, 196, 140, 0.26);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    width: 95%;
    border-radius: 50px;
  }

  .presaleT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    color: #599993;
  }

  .solP {
    font-size: 14px;
    color: #666666;
    font-weight: bold;
    text-align: center;
    margin-bottom: 8px;
  }

  .tokenMain2 {
    width: 75%;
    text-align: center;
    z-index: 10;
    margin-top: 25%;
    margin-bottom: 3%;
    position: absolute;
  }

  .banner {
    width: 75%;
  }

  .tokenCon {
    text-align: left;
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #1a8116;
  }

  .presale-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;

  }

  /* Input styles */
  .input-container {
    margin-bottom: 20px;
    display: flex;
    border: 2px solid #000000;
    border-radius: 1px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: absolute;
    margin-top: 54%;
    margin-left: 12.9%;
    z-index: 5;
    border-radius: 30px;    
  }

  .inSol {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: row nowrap;
    margin-right: 30px;
    font-size: 16px;
    font-family: 'Edo', sans-serif;

  }

  .input-field {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
    text-align: center;
    border: none;
    background: none;
  }

  .input-field:focus {
    border-color: #1a8116;
  }

  /* Button styles */
  .mint-btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #1a8116;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    width: 260px;
    border-radius: 1px;
    z-index: 5;
    position: absolute;
    margin-top: 66%;
    margin-left: 13%;
    border-radius: 50px;
    font-family: 'Edo', sans-serif;

  }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
  }

  .stones {
    width: 100%;
  }



  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logoDiv img {
    width: 80px;
    border-radius: 100px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 15px;
    cursor: pointer;
    color: #6e6e4c;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: bold;
  }

  .mintBtn {
    font-size: 17px;
    background-color: #e3ff96;
    border: 1px #788f38 solid;
    border-radius: 12px;
    padding: 12px;
    color: black;
    margin-left: 15px;
  }

  #fontSize:hover {
    color: #05a500;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 25px;
    margin-right: 25px;
  }

  .allWrap {
    background: rgb(33, 66, 0);
    background: linear-gradient(299deg, rgba(33, 66, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(19, 38, 0, 1) 100%);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: url('./assets/bg.jpg') fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont {
    height: max-content;
    background-size: cover;
    text-align: right;
   
  }


  .introduction {
    text-align: left;
    padding-top: 2%;
    padding-bottom: 5%;
    justify-content: space-evenly;
    display: flex;
    position: relative;
    z-index: 1000;
    
    min-height: 80vh;
  }

  .introduction-2 {
    width: 50%;
    margin-left: 5%;

  }

  .introPic {
    width: 40%;
    height: 40%;
    border: 1px solid rgb(208, 255, 0);
    box-shadow: rgba(164, 192, 86, 0.35) 0px 10px 20px;
    rotate: 6deg;
  }

  .social {
    display: flex;
    margin-top: 2%;
    margin-bottom: 1%;
  }

  .social img {
    width: 35px;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .social img:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .soocialDiv {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .buy {
    font-size: 15px;
    background-color: #97e92c;
    border: 1px #2b8a7a solid;
    border-radius: 12px;
    padding: 10px;
    margin-right: 10px;
    color: #093331;
    width: fit-content;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buy:hover {
    background-color: #d1ff05;
  }

  .title {
    font-size: 20px;
    -webkit-text-stroke: 2px rgba(0, 0, 0, 0);
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
  }

  .mememon {
    font-family: 'Comic Kings', sans-serif;
    font-size: 85px;
    color: #ffffff;
    text-shadow: 2px 8px 6px #21352418, 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 2px #9bbe7d;
    line-height: 100px;
  }

  .fontSize2 {
    font-size: 20px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
  }

  .subTitle {
    font-size: 16px;
    width: 90%;
    color: #83aa89;
  }

  .linkMain {
    font-weight: 500;
    width: fit-content;
    justify-content: space-between;
    width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .inputDiv {
    color: rgb(255, 148, 86);
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    outline: none;
    background: none;
  }

  input::placeholder {
    color: #659c32;
    /* Set the desired placeholder color */
  }

  .copy {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  .aboutMain {
    margin-top: 7%;
    text-align: center;
  }

  .flexStone {
    display: flex;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .stoneS {
    width: 25%;
    height: 25%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .stoneS2 {
    width: 10%;
    margin-right: 5%;
   }

  .aboutMain--2 {
    margin-top: 7%;
    text-align: center;
  }

  .imgStone {
    width: 100%;
     z-index: 1000;
    position: relative;

  }

  .conT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    font-size: 60px;
    text-decoration: underline #fa7e1d;
    color: #f3ef7d;
  }

  .conT2 {
    text-align: center;
    font-weight: 400;
    font-size: 25px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
        color: #fff3b2;

  }

  .conT2-2 {
    text-align: center;
    font-weight: 400;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;

  }

  .conT2-HL {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout img {
    width: 40%;
    border-radius: 100px;
    margin-top: -80px;
  }

  .boxAbout {
    background-color: #baf7d4;
    width: 400px;
    padding: 30px;
    border-radius: 80px;
    margin: 15px;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  }

  .boxAbout div {
    font-size: 30px;
    margin-top: 5%;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .boxAbout p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 18px;
  }

  .boxAbout2 img {
    width: 20%;
    margin-bottom: 25px;
  }

  .boxAbout2 {
    margin-bottom: 20px;
    margin: 20px;
    text-align: left;
    background-color: #befff41f;
    width: 80%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px dashed #49d1ba;
  }

  .hlF {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout2 p {
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .box2Main {
    display: flex;
    justify-content: center;
  }

  .boxMain {
    margin-top: 4%;
  }

  .tierMain {
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }

  .tierMain p {
    font-size: 18px;
  }

  .tierSection1 {
    width: 40%;
    padding: 15px;
    border-radius: 30px;
    transform: rotate(-10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
  }

  .tierSection2 {
    width: 40%;
    padding: 15px;
    border-radius: 30px;
    transform: rotate(10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
  }

  .tierSection2-2 {
    margin-top: 35px;
  }

  .tierH {
    font-family: "Kalam", cursive;
    font-size: 40px;

    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }



  .iconsF {
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 200px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .shell1 {
    width: 18%;
    height: 18%;
    position: sticky;
    margin-left: 3%;
    margin-top: -2%;
  }

  .shell2 {
    margin-top: -9%;
    width: 18%;
    height: 18%;
    margin-right: 3%;
  }

  .shells2 {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 7%;
    padding-bottom: 5%;
  }

  .copyright {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 28px;
    margin-top: 20px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .introPicMob{
    display: none;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.856);
    z-index: 1000000000000000;
  }

  .popUpDiv {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 70%;
    height: 85vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 1000000000000000;
    display: flex;
    flex-flow: row nowrap;
    animation: popupAnimation 0.5s ease forwards;
    color: white;
    flex-flow: column nowrap;
    position: relative;
  }

  .gem {
    width: 25%;
    height: 25%;
    margin-top: -25%;
    z-index: 1000000;
    position: relative;
    margin-left: 40px;
  }

  .note4{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 16px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 13%;  
    margin-left: 13%;    
    z-index: 10;
  }
 

  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
     position: absolute;
    z-index: 10;
    font-family: 'Edo', sans-serif;
    margin-top: 17%;
    margin-left: 11.5%;
    border: 1px solid black;
    border-radius: 20px;
    padding: 5px;
    padding: 5px;
    padding-bottom: 10px;
  }

  
.start{
  text-align: center;
  font-size: 1.5rem;
}
  
  .timer div {
    text-align: center;
    font-family: 'Edo', sans-serif;
    width: 73px;
  }
  
  .timer div span {
    display: block;
    font-size: 0.8rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  
  }
  
  .noteMain{
    position: relative;
    rotate: -3deg;
  }
  
  .noteImg{
    position: absolute;
    width: 76%;
    margin-top: -8%;
  }
  
  .note1{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 19px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 2.4%;  
    margin-left: 13%;                                 
  }
  
  .note2{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 32px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 6%;  
    margin-left: 27%;   
    rotate: -6deg;                              
  }
  
  .note3{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 19px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 31%;  
    margin-left: 12%;    
  }
  
  .note {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 18px;
    z-index: 10;
    position: absolute;
    margin-top: 61%;
    margin-left: 18%;
  }

  .tokenMain {
    background: rgb(120, 232, 173);
    background: radial-gradient(circle, rgba(120, 232, 172, 0.315) 0%, rgba(23, 182, 161, 0.171) 50%, rgba(80, 251, 251, 0.24) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(9, 196, 140, 0.26);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    width: 95%;
    border-radius: 50px;
  }

  .presaleT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    color: #599993;
  }

  .solP {
    font-size: 16px;
    color: #666666;
    font-weight: bold;
    text-align: center;
    margin-top: 2px;
  }

  .tokenMain2 {
    width: 75%;
    text-align: center;
    z-index: 10;
    margin-top: 25%;
    margin-bottom: 3%;
    position: absolute;
  }

  .banner {
    width: 75%;
  }

  .tokenCon {
    text-align: left;
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #1a8116;
  }

  .presale-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;

  }

  /* Input styles */
  .input-container {
    margin-bottom: 20px;
    display: flex;
    border: 2px solid #000000;
    border-radius: 1px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: absolute;
    margin-top: 43.5%;
    margin-left: 13%;
    z-index: 5;
    border-radius: 30px;
    
  }

  .inSol {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: row nowrap;
    margin-right: 30px;
    font-size: 16px;
    font-family: 'Edo', sans-serif;

  }

  .input-field {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
    text-align: center;
    border: none;
    background: none;
  }

  .input-field:focus {
    border-color: #1a8116;
  }

  /* Button styles */
  .mint-btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #1a8116;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    width: 40%;
    border-radius: 1px;
    z-index: 5;
    position: absolute;
    margin-top: 53%;
    margin-left: 13%;
    border-radius: 50px;
    font-family: 'Edo', sans-serif;

  }

  .videoBg {
    position: absolute;
    top: -70px;
    left: 0px;
    max-width: 100%;
  }

  .stones {
    width: 100%;
  }



  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logoDiv img {
    width: 80px;
    border-radius: 100px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 15px;
    cursor: pointer;
    color: #6e6e4c;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: bold;
  }

  .mintBtn {
    font-size: 17px;
    background-color: #e3ff96;
    border: 1px #788f38 solid;
    border-radius: 12px;
    padding: 12px;
    color: #000000;
    margin-left: 15px;
  }

  #fontSize:hover {
    color: #05a500;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 25px;
    margin-right: 25px;
  }

  .allWrap {
    background: rgb(33, 66, 0);
    background: linear-gradient(299deg, rgba(33, 66, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(19, 38, 0, 1) 100%);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: url('./assets/bg.jpg') fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont {
    height: max-content;
    background-size: cover;
    text-align: right;
  }

  .introduction {
    text-align: left;
    padding-top: 2%;
    padding-bottom: 5%;
    justify-content: space-evenly;
    display: flex;
    position: relative;
    z-index: 1000;
    min-height: 100vh;
  }

  .introduction-2 {
    width: 50%;
    margin-left: 5%;
  }

  .introPic {
    width: 40%;
    height: 40%;
    border: 1px solid rgb(208, 255, 0);
    box-shadow: rgba(164, 192, 86, 0.35) 0px 10px 20px;
    rotate: 6deg;
  }

  .social {
    display: flex;
    margin-top: 2%;
    margin-bottom: 1%;
  }

  .social img {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .social img:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .soocialDiv {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .buy {
    font-size: 17px;
    background-color: #97e92c;
    border: 1px #2b8a7a solid;
    border-radius: 12px;
    padding: 12px;
    margin-right: 10px;
    color: #093331;
    width: fit-content;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buy:hover {
    background-color: #d1ff05;
  }

  .title {
    font-size: 25px;
    -webkit-text-stroke: 2px rgba(0, 0, 0, 0);
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
  }

  .mememon {
    font-family: 'Comic Kings', sans-serif;
    font-size: 120px;
    color: #ffffff;
    text-shadow: 2px 8px 6px #21352418, 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 2px #9bbe7d;
    line-height: 130px;
  }

  .fontSize2 {
    font-size: 25px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
  }

  .subTitle {
    font-size: 16px;
    width: 90%;
    color: #83aa89;
  }

  .linkMain {
    font-weight: 500;
    width: fit-content;
    justify-content: space-between;
    width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .inputDiv {
    color: rgb(255, 148, 86);
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    outline: none;
    background: none;
  }

  input::placeholder {
    color: #659c32;
    /* Set the desired placeholder color */
  }

  .copy {
    width: 25px;
    height: 25px;
    margin-left: 20px;
  }

  .aboutMain {
    margin-top: 7%;
    text-align: center;
  }

  .flexStone {
    display: flex;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .stoneS {
    width: 20%;
    height: 20%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .stoneS2 {
    width: 10%;
    margin-right: 5%;
   }

  .aboutMain--2 {
    margin-top: 7%;
    text-align: center;
  }

  .imgStone {
    width: 100%;
    margin-top: -80px;
    z-index: 1000;
    position: relative;
  }

  .x{
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .navT{
    font-family: 'Edo', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }

  .logoDiv{
    display: flex;
  }

  .conT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    font-size: 60px;
    text-decoration: underline #fa7e1d;
    color: #f3ef7d;
  }

  .conT2 {
    text-align: center;
    font-weight: 400;
    font-size: 25px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;
  }

  .conT2-2 {
    text-align: center;
    font-weight: 400;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;

  }

  .conT2-HL {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout img {
    width: 40%;
    border-radius: 100px;
    margin-top: -80px;
  }

  .boxAbout {
    background-color: #baf7d4;
    width: 400px;
    padding: 30px;
    border-radius: 80px;
    margin: 15px;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  }

  .boxAbout div {
    font-size: 30px;
    margin-top: 5%;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .boxAbout p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 18px;
  }

  .boxAbout2 img {
    width: 20%;
    margin-bottom: 25px;
  }

  .boxAbout2 {
    margin-bottom: 20px;
    margin: 20px;
    text-align: left;
    background-color: #befff41f;
    width: 50%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px dashed #49d1ba;
  }

  .hlF {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout2 p {
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .box2Main {
    display: flex;
    justify-content: center;
  }

  .boxMain {
    margin-top: 4%;
  }

  .tierMain {
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }

  .tierMain p {
    font-size: 18px;
  }

  .tierSection1 {
    width: 40%;
    padding: 60px;
    border-radius: 30px;
    transform: rotate(-10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
  }

  .tierSection2 {
    width: 40%;
    padding: 60px;
    border-radius: 30px;
    transform: rotate(10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
  }

  .tierSection2-2 {
    margin-top: 35px;
  }

  .tierH {
    font-family: "Kalam", cursive;
    font-size: 40px;

    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }


  .iconsF {
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 200px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .shell1 {
    width: 18%;
    height: 18%;
    position: sticky;
    margin-left: 3%;
    margin-top: -2%;
    margin-top: -2%;
  }

  .shell2 {
    margin-top: -9%;
    width: 18%;
    height: 18%;
    margin-right: 3%;
  }

  .shells2 {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 7%;
    padding-bottom: 5%;
  }

  .copyright {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 28px;
    margin-top: 20px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }
}

@media screen and (min-width: 1920px) {
  .introPicMob{
    display: none;
  }

  .x{
    width: 55px;
    height: 55px;
    cursor: pointer;
    margin-right: 10px;
  }

  .navT{
    font-family: 'Edo', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 25px;
    font-size: 55px;
  }

  .logoDiv{
    display: flex;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.856);
    z-index: 1000000000000000;
  }

  .popUpDiv {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 70%;
    height: 85vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 1000000000000000;
    display: flex;
    flex-flow: row nowrap;
    animation: popupAnimation 0.5s ease forwards;
    color: white;
    flex-flow: column nowrap;
    position: relative;
  }

  .gem {
    width: 25%;
    height: 25%;
    margin-top: -25%;
    z-index: 1000000;
    position: relative;
    margin-left: 40px;
  }

  .note4{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 24px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 13%;  
    margin-left: 13%;    
    z-index: 10;
  }
  
  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2.9rem;
     position: absolute;
    z-index: 10;
    font-family: 'Edo', sans-serif;
    margin-top: 17%;
    margin-left: 11.5%;
    border: 1px solid black;
    border-radius: 20px;
    padding-bottom: 10px;
    padding: 5px;

  }
  
  .timer div {
    text-align: center;
    font-family: 'Edo', sans-serif;
    width: 103px;
  }
  
  .timer div span {
    display: block;
    font-size: 1.2rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  
  }
  
  .noteMain{
    position: relative;
    rotate: -3deg;
  }
  
  .noteImg{
    position: absolute;
    width: 76%;
    margin-top: -8%;
  }
  
  .note1{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 27px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 2.4%;  
    margin-left: 13%;                                 
  }
  
  .note2{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 45px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 6%;  
    margin-left: 27%;   
    rotate: -6deg;                              
  }
  
  .note3{
    font-family: 'Edo', sans-serif;
    color: black;
    z-index: 5;   
    position: absolute;    
    margin-top: 32%;  
    margin-left: 12%;    
  }
  
  .note {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 26.5px;
    z-index: 10;
    position: absolute;
    margin-top: 61%;
    margin-left: 18%;
  }

  .tokenMain {
    background: rgb(120, 232, 173);
    background: radial-gradient(circle, rgba(120, 232, 172, 0.315) 0%, rgba(23, 182, 161, 0.171) 50%, rgba(80, 251, 251, 0.24) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(9, 196, 140, 0.26);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    width: 95%;
    border-radius: 50px;
  }

  .presaleT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    color: #599993;
  }

  .solP {
    font-size: 24px;
    color: #666666;
    font-weight: bold;
    text-align: center;
    margin-top: 2px;
  }

  .tokenMain2 {
    width: 75%;
    text-align: center;
    z-index: 10;
    margin-top: 25%;
    margin-bottom: 3%;
    position: absolute;
  }

  .banner {
    width: 75%;
  }

  .tokenCon {
    text-align: left;
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #1a8116;
  }

  .presale-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;

  }

  /* Input styles */
  .input-container {
    margin-bottom: 20px;
    display: flex;
    border: 3px solid #000000;
    border-radius: 1px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: absolute;
    margin-top: 45%;
    margin-left: 14%;
    z-index: 5;
    border-radius: 30px;
    
  }

  .inSol {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: row nowrap;
    margin-right: 30px;
    font-size: 28px;
    font-family: 'Edo', sans-serif;

  }

  .input-field {
    width: 100%;
    padding: 10px;
    font-size: 22px;
    outline: none;
    transition: border-color 0.3s ease;
    text-align: center;
    border: none;
    background: none;
  }

  .input-field:focus {
    border-color: #1a8116;
  }

  /* Button styles */
  .mint-btn {
    display: inline-block;
    padding: 13px 25px;
    font-size: 26px;
    font-weight: bold;
    color: #fff;
    background-color: #1a8116;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    width: 40%;
    border-radius: 1px;
    z-index: 5;
    position: absolute;
    margin-top: 53%;
    margin-left: 13%;
    border-radius: 50px;
    font-family: 'Edo', sans-serif;
  }
 
  .videoBg {
    position: absolute;
    top: -70px;
    left: 0px;
    max-width: 100%;
    height: 100%;
  }

  .stones {
    width: 100%;
  }



  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logoDiv img {
    width: 120px;
    border-radius: 100px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 22px;
    cursor: pointer;
    color: #6e6e4c;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: bold;
  }

  .mintBtn {
    font-size: 24px;
    background-color: #e3ff96;
    border: 1px #788f38 solid;
    border-radius: 16px;
    padding: 18px;
    color: black;
    margin-left: 15px;
  }

  #fontSize:hover {
    color: #05a500;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 35px;
    margin-right: 35px;
  }

  .allWrap {
    background: rgb(33, 66, 0);
    background: linear-gradient(299deg, rgba(33, 66, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(19, 38, 0, 1) 100%);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: url('./assets/bg.jpg') fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont {
    height: max-content;
    background-size: cover;
    text-align: right;   
  }

  .introduction {
    text-align: left;
    padding-top: 2%;
    padding-bottom: 5%;
    justify-content: space-evenly;
    display: flex;
    position: relative;
    z-index: 1000;
    
    min-height: 85vh;
  }

  .introduction-2 {
    width: 50%;
    margin-left: 5%;

  }

  .introPic {
    width: 40%;
    height: 40%;
    border: 1px solid rgb(208, 255, 0);
    box-shadow: rgba(164, 192, 86, 0.35) 0px 10px 20px;
    rotate: 6deg;
  }

  .social {
    display: flex;
    margin-top: 2%;
    margin-bottom: 1%;
  }

  .social img {
    width: 55px;
    height: 55px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .social img:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .soocialDiv {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .buy {
    font-size: 24px;
    background-color: #97e92c;
    border: 2px #2b8a7a solid;
    border-radius: 15px;
    padding: 18px;
    margin-right: 20px;
    color: #093331;
    width: fit-content;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buy:hover {
    background-color: #d1ff05;
  }

  .title {
    font-size: 35px;
    -webkit-text-stroke: 2px rgba(0, 0, 0, 0);
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
  }

  .mememon {
    font-family: 'Comic Kings', sans-serif;
    font-size: 165px;
    color: #ffffff;
    text-shadow: 2px 8px 6px #21352418, 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 2px #9bbe7d;
    line-height: 180px;
  }

  .fontSize2 {
    font-size: 35px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
  }

  .subTitle {
    font-size: 16px;
    width: 90%;
    color: #83aa89;
  }

  .linkMain {
    font-weight: 500;
    width: fit-content;
    justify-content: space-between;
    width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .inputDiv {
    color: rgb(255, 148, 86);
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    outline: none;
    background: none;
  }

  input::placeholder {
    color: #659c32;
    /* Set the desired placeholder color */
  }

  .copy {
    width: 25px;
    height: 25px;
    margin-left: 20px;
  }

  .aboutMain {
    margin-top: 7%;
    text-align: center;
  }

  .flexStone {
    display: flex;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .stoneS {
    width: 20%;
    height: 20%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }


  .stoneS2 {
    width: 10%;
    margin-right: 5%;
   }

  .aboutMain--2 {
    margin-top: 7%;
    text-align: center;
  }

  .imgStone {
    width: 100%;
    margin-top: -130px;
    z-index: 1000;
    position: relative;

  }

  .conT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    font-size: 90px;
    text-decoration: underline #fa7e1d;
    color: #f3ef7d;
  }

  .conT2 {
    text-align: center;
    font-weight: 400;
    font-size: 38px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;
    margin-bottom: 30px;
  }

  .conT2-2 {
    text-align: center;
    font-weight: 400;
    font-size: 38px;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;
    margin-bottom: 30px;

  }

  .conT2-HL {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout img {
    width: 40%;
    border-radius: 100px;
    margin-top: -120px;
  }

  .boxAbout {
    background-color: #baf7d4;
    width: 550px;
    padding: 40px;
    border-radius: 120px;
    margin: 30px;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .boxAbout div {
    font-size: 45px;
    margin-top: 5%;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .boxAbout p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 24px;
  }

  .boxAbout2 img {
    width: 20%;
    margin-bottom: 25px;
  }

  .boxAbout2 {
    margin-bottom: 20px;
    margin: 20px;
    text-align: left;
    background-color: #befff41f;
    width: 50%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px dashed #49d1ba;
    font-size: 22px;
  }

  .hlF {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout2 p {
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .box2Main {
    display: flex;
    justify-content: center;
  }

  .boxMain {
    margin-top: 4%;
  }

  .tierMain {
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }

  .tierMain p {
    font-size: 24px;
  }

  .tierSection1 {
    width: 40%;
    padding: 100px;
    border-radius: 30px;
    transform: rotate(-10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
  }

  .tierSection2 {
    width: 40%;
    padding: 100px;
    border-radius: 30px;
    transform: rotate(10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
  }

  .tierSection2-2 {
    margin-top: 45px;
  }

  .tierH {
    font-family: "Kalam", cursive;
    font-size: 55px;

    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }



  .iconsF {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .iconsF img {
    width: 100px;
    height: 100px;
    margin-left: 25px;
    margin-right: 25px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 300px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .shell1 {
    width: 18%;
    height: 18%;
    position: sticky;
    margin-left: 3%;
    margin-top: -2%;
  }

  .shell2 {
    margin-top: -9%;
    width: 18%;
    height: 18%;
    margin-right: 3%;
  }

  .shells2 {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 7%;
    padding-bottom: 5%;
  }

  .copyright {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 38px;
    margin-top: 20px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .introPicMob{
    display: none;
  }

  .x{
    width: 78px;
    height: 78px;
    cursor: pointer;
    margin-right: 10px;
  }

  .navT{
    font-family: 'Edo', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 35px;
    font-size: 75px;
  }

  .logoDiv{
    display: flex;
  }


  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.856);
    z-index: 1000000000000000;
  }

  .popUpDiv {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 70%;
    height: 85vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 1000000000000000;
    display: flex;
    flex-flow: row nowrap;
    animation: popupAnimation 0.5s ease forwards;
    color: white;
    flex-flow: column nowrap;
    position: relative;
  }

  .gem {
    width: 25%;
    height: 25%;
    margin-top: -25%;
    z-index: 1000000;
    position: relative;
    margin-left: 40px;
  }

  .note4{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 32px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 13%;  
    margin-left: 13%;    
    z-index: 10;
  }
 
  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 3.8rem;
     position: absolute;
    z-index: 10;
    font-family: 'Edo', sans-serif;
    margin-top: 17%;
    margin-left: 11.5%;
    border: 1px solid black;
    border-radius: 20px;
    padding-bottom: 18px;
    padding: 5px;

  }
  
  .timer div {
    text-align: center;
    font-family: 'Edo', sans-serif;
    width: 138px;
  }
  
  .timer div span {
    display: block;
    font-size: 1.6rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  
  }
  
  .noteMain{
    position: relative;
    rotate: -3deg;
  }
  
  .noteImg{
    position: absolute;
    width: 76%;
    margin-top: -8%;
  }
  
  .note1{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 36px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 2.4%;  
    margin-left: 13%;                                 
  }
  
  .note2{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 58px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 6%;  
    margin-left: 27%;   
    rotate: -6deg;                              
  }
  
  .note3{
    font-family: 'Edo', sans-serif;
    color: black;
    z-index: 5;   
    position: absolute;    
    margin-top: 31%;  
    margin-left: 12%;    
  }
  
  .note {
    text-align: center;
    color: black;
    font-size: 38px;
    z-index: 10;
    position: absolute;
    margin-top: 59%;
    margin-left: 17%;
  }

  .tokenMain {
    background: rgb(120, 232, 173);
    background: radial-gradient(circle, rgba(120, 232, 172, 0.315) 0%, rgba(23, 182, 161, 0.171) 50%, rgba(80, 251, 251, 0.24) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(9, 196, 140, 0.26);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    width: 95%;
    border-radius: 50px;
  }

  .presaleT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    color: #599993;
  }

  .solP {
    font-size: 32px;
    color: #666666;
    font-weight: bold;
    text-align: center;
    margin-top: 2px;
  }

  .tokenMain2 {
    width: 75%;
    text-align: center;
    z-index: 10;
    margin-top: 25%;
    margin-bottom: 3%;
    position: absolute;
  }

  .banner {
    width: 75%;
  }

  .tokenCon {
    text-align: left;
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #1a8116;
  }

  .presale-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;

  }

  /* Input styles */
  .input-container {
    margin-bottom: 20px;
    display: flex;
    border: 3px solid #000000;
    border-radius: 1px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: absolute;
    margin-top: 44%;
    margin-left: 14%;
    z-index: 5;
    border-radius: 30px;
    
  }

  .inSol {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: row nowrap;
    margin-right: 30px;
    font-size: 35px;
    font-family: 'Edo', sans-serif;

  }

  .input-field {
    width: 100%;
    padding: 10px;
    font-size: 32px;
    outline: none;
    transition: border-color 0.3s ease;
    text-align: center;
    border: none;
    background: none;
  }

  .input-field:focus {
    border-color: #1a8116;
  }

  /* Button styles */
  .mint-btn {
    display: inline-block;
    padding: 13px 25px;
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    background-color: #1a8116;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    width: 40%;
    border-radius: 1px;
    z-index: 5;
    position: absolute;
    margin-top: 51%;
    margin-left: 13.5%;
    border-radius: 50px;
    font-family: 'Edo', sans-serif;
  }
 
  .videoBg {
    position: absolute;
    top: -70px;
    left: 0px;
    max-width: 100%;
    height: 100%;
  }

  .stones {
    width: 100%;
  }



  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .logoDiv img {
    width: 160px;
    border-radius: 100px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 30px;
    cursor: pointer;
    color: #6e6e4c;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: bold;
  }

  .mintBtn {
    font-size: 34px;
    background-color: #e3ff96;
    border: 1px #788f38 solid;
    border-radius: 22px;
    padding: 24px;
    color: black;
    margin-left: 25px;
  }

  #fontSize:hover {
    color: #05a500;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 50px;
    margin-right: 50px;
  }

  .allWrap {
    background: rgb(33, 66, 0);
    background: linear-gradient(299deg, rgba(33, 66, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(19, 38, 0, 1) 100%);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: url('./assets/bg.jpg') fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont {
    height: max-content;
    background-size: cover;
    text-align: right;
   }


  .introduction {
    text-align: left;
    padding-top: 2%;
    padding-bottom: 5%;
    justify-content: space-evenly;
    display: flex;
    position: relative;
    z-index: 1000;
    
    min-height: 85vh;
  }

  .introduction-2 {
    width: 50%;
    margin-left: 5%;

  }

  .introPic {
    width: 40%;
    height: 40%;
    border: 1px solid rgb(208, 255, 0);
    box-shadow: rgba(164, 192, 86, 0.35) 0px 10px 20px;
    rotate: 6deg;
  }

  .social {
    display: flex;
    margin-top: 2%;
    margin-bottom: 1%;
  }

  .social img {
    width: 75px;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .social img:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .soocialDiv {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .buy {
    font-size: 34px;
    background-color: #97e92c;
    border: 3px #2b8a7a solid;
    border-radius: 20px;
    padding: 22px;
    margin-right: 15px;
    color: #093331;
    width: fit-content;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buy:hover {
    background-color: #d1ff05;
  }

  .title {
    font-size: 45px;
    -webkit-text-stroke: 2px rgba(0, 0, 0, 0);
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
  }

  .mememon {
    font-family: 'Comic Kings', sans-serif;
    font-size: 210px;
    color: #ffffff;
    text-shadow: 2px 8px 6px #21352418, 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 4px #9bbe7d;
    line-height: 240px;
  }

  .fontSize2 {
    font-size: 45px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
  }

  .subTitle {
    font-size: 16px;
    width: 90%;
    color: #83aa89;
  }

  .linkMain {
    font-weight: 500;
    width: fit-content;
    justify-content: space-between;
    width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .inputDiv {
    color: rgb(255, 148, 86);
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    outline: none;
    background: none;
  }

  input::placeholder {
    color: #659c32;
    /* Set the desired placeholder color */
  }

  .copy {
    width: 40px;
    height: 40px;
    margin-left: 30px;
  }

  .aboutMain {
    margin-top: 7%;
    text-align: center;
  }

  .flexStone {
    display: flex;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .stoneS {
    width: 20%;
    height: 20%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }


  .stoneS2 {
    width: 10%;
    margin-right: 5%;
   }

  .aboutMain--2 {
    margin-top: 7%;
    text-align: center;
  }

  .imgStone {
    width: 100%;
    margin-top: -180px;
    z-index: 1000;
    position: relative;
  }

  .conT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    font-size: 120px;
    text-decoration: underline #fa7e1d;
    color: #f3ef7d;
  }

  .conT2 {
    text-align: center;
    font-weight: 400;
    font-size: 48px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;
    margin-bottom: 30px;
  }

  .conT2-2 {
    text-align: center;
    font-weight: 400;
    font-size: 38px;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;
    margin-bottom: 30px;

  }

  .conT2-HL {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout img {
    width: 40%;
    border-radius: 100px;
    margin-top: -165px;
  }

  .boxAbout {
    background-color: #baf7d4;
    width: 760px;
    padding: 50px;
    border-radius: 120px;
    margin: 45px;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .boxAbout div {
    font-size: 60px;
    margin-top: 5%;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .boxAbout p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 34px;
  }

  .boxAbout2 img {
    width: 20%;
    margin-bottom: 25px;
  }

  .boxAbout2 {
    margin-bottom: 20px;
    margin: 20px;
    text-align: left;
    background-color: #befff41f;
    width: 50%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 60px;
    padding: 35px;
    border-radius: 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 7px dashed #49d1ba;
    font-size: 32px;
  }

  .hlF {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout2 p {
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .box2Main {
    display: flex;
    justify-content: center;
  }

  .boxMain {
    margin-top: 4%;
  }

  .tierMain {
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }

  .tierMain p {
    font-size: 34px;
  }

  .tierSection1 {
    width: 40%;
    padding: 130px;
    border-radius: 30px;
    transform: rotate(-10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
  }

  .tierSection2 {
    width: 40%;
    padding: 130px;
    border-radius: 30px;
    transform: rotate(10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
  }

  .tierSection2-2 {
    margin-top: 70px;
  }

  .tierH {
    font-family: "Kalam", cursive;
    font-size: 75px;

    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }



  .iconsF {
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .iconsF img {
    width: 130px;
    height: 130px;
    margin-left: 40px;
    margin-right: 40px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 400px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .shell1 {
    width: 18%;
    height: 18%;
    position: sticky;
    margin-left: 3%;
    margin-top: -2%;
  }

  .shell2 {
    margin-top: -9%;
    width: 18%;
    height: 18%;
    margin-right: 3%;
  }

  .shells2 {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 7%;
    padding-bottom: 5%;
  }

  .copyright {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 53px;
    margin-top: 20px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }
}

@media screen and (min-width: 3840px) {
  .introPicMob{
    display: none;
  }

  .x{
    width: 78px;
    height: 78px;
    cursor: pointer;
    margin-right: 10px;
  }

  .navT{
    font-family: 'Edo', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 35px;
    font-size: 95px;
  }

  .logoDiv{
    display: flex;
  }


  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.856);
    z-index: 1000000000000000;
  }

  .popUpDiv {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 70%;
    height: 85vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 1000000000000000;
    display: flex;
    flex-flow: row nowrap;
    animation: popupAnimation 0.5s ease forwards;
    color: white;
    flex-flow: column nowrap;
    position: relative;
  }

  .gem {
    width: 25%;
    height: 25%;
    margin-top: -25%;
    z-index: 1000000;
    position: relative;
    margin-left: 40px;
  }

  .note4{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 45px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 13%;  
    margin-left: 13%;    
    z-index: 10;
  }
  
  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 6rem;
     position: absolute;
    z-index: 10;
    font-family: 'Edo', sans-serif;
    margin-top: 17%;
    margin-left: 11.5%;
    border: 1px solid black;
    border-radius: 20px;
    padding-bottom: 18px;
    padding: 5px;

  }
  
  .timer div {
    text-align: center;
    font-family: 'Edo', sans-serif;
    width: 207px;
  }
  
  .timer div span {
    display: block;
    font-size: 2.5rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  
  }
  
  .noteMain{
    position: relative;
    rotate: -3deg;
  }
  
  .noteImg{
    position: absolute;
    width: 76%;
    margin-top: -8%;
  }
  
  .note1{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 55px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 2.4%;  
    margin-left: 13%;                                 
  }
  
  .note2{
    font-family: 'Edo', sans-serif;
    color: black;
    font-size: 80px;   
    z-index: 5;   
    position: absolute;    
    margin-top: 6%;  
    margin-left: 27%;   
    rotate: -6deg;                              
  }
  
  .note3{
    font-family: 'Edo', sans-serif;
    color: black;
    z-index: 5;   
    position: absolute;    
    margin-top: 32%;  
    margin-left: 12%;    
  }
  
  .note {
    text-align: center;
    color: black;
    font-size: 55px;
    z-index: 10;
    position: absolute;
    margin-top: 60%;
    margin-left: 17%;
  }

  .tokenMain {
    background: rgb(120, 232, 173);
    background: radial-gradient(circle, rgba(120, 232, 172, 0.315) 0%, rgba(23, 182, 161, 0.171) 50%, rgba(80, 251, 251, 0.24) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(9, 196, 140, 0.26);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    width: 95%;
    border-radius: 50px;
  }

  .presaleT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    color: #599993;
  }

  .solP {
    font-size: 48px;
    color: #666666;
    font-weight: bold;
    text-align: center;
    margin-top: 2px;
  }

  .tokenMain2 {
    width: 75%;
    text-align: center;
    z-index: 10;
    margin-top: 25%;
    margin-bottom: 3%;
    position: absolute;
  }

  .banner {
    width: 75%;
  }

  .tokenCon {
    text-align: left;
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #1a8116;
  }

  .presale-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;

  }

  /* Input styles */
  .input-container {
    margin-bottom: 20px;
    display: flex;
    border: 3px solid #000000;
    border-radius: 1px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: absolute;
    margin-top: 45%;
    margin-left: 14%;
    z-index: 5;
    border-radius: 100px;
    
  }

  .inSol {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: row nowrap;
    margin-right: 30px;
    font-size: 50px;
    font-family: 'Edo', sans-serif;

  }

  .input-field {
    width: 100%;
    padding: 10px;
    font-size: 50px;
    outline: none;
    transition: border-color 0.3s ease;
    text-align: center;
    border: none;
    background: none;
  }

  .input-field:focus {
    border-color: #1a8116;
  }

  /* Button styles */
  .mint-btn {
    display: inline-block;
    padding: 30px 45px;
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    background-color: #1a8116;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    width: 40%;
    border-radius: 1px;
    z-index: 5;
    position: absolute;
    margin-top: 52%;
    margin-left: 13.5%;
    border-radius: 50px;
    font-family: 'Edo', sans-serif;
  }
 
  
  .videoBg {
    position: absolute;
    top: -70px;
    left: 0px;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  .stones {
    width: 100%;
  }



  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .logoDiv img {
    width: 160px;
    border-radius: 100px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 30px;
    cursor: pointer;
    color: #6e6e4c;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: bold;
  }

  .mintBtn {
    font-size: 34px;
    background-color: #e3ff96;
    border: 1px #788f38 solid;
    border-radius: 22px;
    padding: 24px;
    color: black;
    margin-left: 25px;
  }

  #fontSize:hover {
    color: #05a500;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 50px;
    margin-right: 50px;
  }

  .allWrap {
    background: rgb(33, 66, 0);
    background: linear-gradient(299deg, rgba(33, 66, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(19, 38, 0, 1) 100%);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: url('./assets/bg.jpg') fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont {
    height: max-content;
    background-size: cover;
    text-align: right;
   
  }


  .introduction {
    text-align: left;
    padding-top: 2%;
    padding-bottom: 5%;
    justify-content: space-evenly;
    display: flex;
    position: relative;
    z-index: 1000;
    
    min-height: 85vh;
  }

  .introduction-2 {
    width: 50%;
    margin-left: 5%;

  }

  .introPic {
    width: 40%;
    height: 40%;
    border: 1px solid rgb(208, 255, 0);
    box-shadow: rgba(164, 192, 86, 0.35) 0px 10px 20px;
    rotate: 6deg;
  }

  .social {
    display: flex;
    margin-top: 2%;
    margin-bottom: 1%;
  }

  .social img {
    width: 75px;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .social img:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .soocialDiv {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .buy {
    font-size: 34px;
    background-color: #97e92c;
    border: 3px #2b8a7a solid;
    border-radius: 20px;
    padding: 22px;
    margin-right: 15px;
    color: #093331;
    width: fit-content;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buy:hover {
    background-color: #d1ff05;
  }

  .title {
    font-size: 45px;
    -webkit-text-stroke: 2px rgba(0, 0, 0, 0);
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
  }

  .mememon {
    font-family: 'Comic Kings', sans-serif;
    font-size: 210px;
    color: #ffffff;
    text-shadow: 2px 8px 6px #21352418, 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 4px #9bbe7d;
    line-height: 240px;
  }

  .fontSize2 {
    font-size: 45px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    color: white;
  }

  .subTitle {
    font-size: 16px;
    width: 90%;
    color: #83aa89;
  }

  .linkMain {
    font-weight: 500;
    width: fit-content;
    justify-content: space-between;
    width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .inputDiv {
    color: rgb(255, 148, 86);
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    outline: none;
    background: none;
  }

  input::placeholder {
    color: #659c32;
    /* Set the desired placeholder color */
  }

  .copy {
    width: 50px;
    height: 50px;
    margin-left: 40px;
  }

  .aboutMain {
    margin-top: 7%;
    text-align: center;
  }

  .flexStone {
    display: flex;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .stoneS {
    width: 20%;
    height: 20%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }


  .stoneS2 {
    width: 10%;
    margin-right: 5%;
   }

  .aboutMain--2 {
    margin-top: 7%;
    text-align: center;
  }

  .imgStone {
    width: 100%;
    margin-top: -380px;
    z-index: 1000;
    position: relative;

  }

  .conT {
    font-family: 'Rusty Forest PERSONAL USE', sans-serif;
    font-size: 120px;
    text-decoration: underline #fa7e1d;
    color: #f3ef7d;
  }

  .conT2 {
    text-align: center;
    font-weight: 400;
    font-size: 48px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;
    margin-bottom: 30px;
  }

  .conT2-2 {
    text-align: center;
    font-weight: 400;
    font-size: 38px;
    margin-left: auto;
    margin-right: auto;
    color: #fff3b2;
    margin-bottom: 30px;

  }

  .conT2-HL {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout img {
    width: 40%;
    border-radius: 100px;
    margin-top: -165px;
  }

  .boxAbout {
    background-color: #baf7d4;
    width: 760px;
    padding: 50px;
    border-radius: 120px;
    margin: 45px;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .boxAbout div {
    font-size: 60px;
    margin-top: 5%;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .boxAbout p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 34px;
  }

  .boxAbout2 img {
    width: 20%;
    margin-bottom: 25px;
  }

  .boxAbout2 {
    margin-bottom: 20px;
    margin: 20px;
    text-align: left;
    background-color: #befff41f;
    width: 50%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 60px;
    padding: 35px;
    border-radius: 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 7px dashed #49d1ba;
    font-size: 32px;
  }

  .hlF {
    color: #fff3b2;
    font-weight: bold;
  }

  .boxAbout2 p {
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .box2Main {
    display: flex;
    justify-content: center;
  }

  .boxMain {
    margin-top: 4%;
  }

  .tierMain {
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }

  .tierMain p {
    font-size: 34px;
  }

  .tierSection1 {
    width: 40%;
    padding: 130px;
    border-radius: 30px;
    transform: rotate(-10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
  }

  .tierSection2 {
    width: 40%;
    padding: 130px;
    border-radius: 30px;
    transform: rotate(10deg);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: bold;
  }

  .tierSection2-2 {
    margin-top: 70px;
  }

  .tierH {
    font-family: "Kalam", cursive;
    font-size: 75px;

    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }



  .iconsF {
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .iconsF img {
    width: 130px;
    height: 130px;
    margin-left: 40px;
    margin-right: 40px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 400px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .shell1 {
    width: 18%;
    height: 18%;
    position: sticky;
    margin-left: 3%;
    margin-top: -2%;
  }

  .shell2 {
    margin-top: -9%;
    width: 18%;
    height: 18%;
    margin-right: 3%;
  }

  .shells2 {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
  }

  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 7%;
    padding-bottom: 5%;
  }

  .copyright {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 53px;
    margin-top: 20px;
    font-family: "Kalam", cursive;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.253), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }
}
